<template>
<div>
    <div class="wrap">
        <div class="con_top">
            <h2><span>■</span> 상담예약등록</h2>
        </div>
        <div class="con">
            <div class="con_table">
                <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                    <tbody>
                        <tr>
                            <th>상담사 <span class="red">*</span></th>
                            <td class="left">
                                <div id="app" v-on:keyup.down="selectValue('down')" v-on:keyup.up="selectValue('up')">
                                    <div class="search">
                                        <input type="text" v-model="staffName" class="ml-20" style="width:200px;" id="counselor" name="counselor" @input="watchEvent($event, 'counselor')">
                                        <ul class="r" tabindex="0" v-bind:class="{ show: isAutoCounselor }" v-on:mouseover="removeValue">
                                            <li tabindex="-1" v-for="(el, index) in filterCounselorList" v-on:click="changeValue(el.name, 'counselor', el.idx, el.idx_crm_center, el.center_name)" v-on:keyup.enter="selectValue('enter', el.name, el.idx, el.idx_crm_center, el.center_name)" :key="index">
                                                <span>{{ el.name }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th style="width:111px;">고객명 <span class="red">*</span></th>
                            <td class="left">
                                <div id="app" v-on:keyup.down="selectValue('down')" v-on:keyup.up="selectValue('up')">
                                    <div class="search">
                                        <input type="text" @click="checkStaff()" v-model="name"  :disabled="eapYn === 'Y'" class="ml-20" style="width:400px;" id="center" name="center" @input="watchEvent($event, 'client')">
                                        <ul class="c" tabindex="0" v-bind:class="{ show: isAutoClient }" v-on:mouseover="removeValue">
                                            <li tabindex="-1" v-for="(el, index) in filterClientList" v-on:click="changeValue(el.name, 'client', el.idx)" v-on:keyup.enter="selectValue('enter', el.name)" :key="index">
                                                <span>{{ el.nickName }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>상담날짜 <span class="red">*</span></th>
                            <td class="left">
                                <crm-datepicker class="ml-20" @input="getStaffSchedule()" v-model="consDate" :parentDate="consDate" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker>
                            </td>
                        </tr>
                        <tr>
                            <th>상담시간 <span class="red">*</span></th>
                            <td class="left">
                                <table class="default_table w-1000px mb-20 mt-20 ml-30">
                                    <tbody>
                                        <tr>
                                            <th>09:00</th>
                                            <th>09:30</th>
                                            <th>10:00</th>
                                            <th>10:30</th>
                                            <th>11:00</th>
                                            <th>11:30</th>
                                            <th>12:00</th>
                                            <th>12:30</th>
                                            <th>13:00</th>
                                            <th>13:30</th>
                                            <th>14:00</th>
                                            <th>14:30</th>
                                            <th>15:00</th>
                                        </tr>
                                        <tr>
                                            <td v-for="(item, idx) of consTimeList.slice(0,13)" :key="idx"><input type="checkbox" v-model="item.use" :disabled="item.can === false" /> </td>
                                        </tr>
                                        <tr>
                                            <th>15:30</th>
                                            <th>16:00</th>
                                            <th>16:30</th>
                                            <th>17:00</th>
                                            <th>17:30</th>
                                            <th>18:00</th>
                                            <th>18:30</th>
                                            <th>19:00</th>
                                            <th>19:30</th>
                                            <th>20:00</th>
                                            <th>20:30</th>
                                            <th>21:00</th>
                                            <th>21:30</th>
                                        </tr>
                                        <tr>
                                            <td v-for="(item, idx) of consTimeList.slice(13,26)" :key="idx"><input type="checkbox" v-model="item.use" :disabled="item.can === false"/> </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <th style="width: 111px;">예약현황 <span class="red">*</span></th>
                            <td class="left">
                                <select v-model="reservationStatus" class="ml-20 w-120px">
                                    <option value="">예약현황선택</option>
                                    <option value="예약">예약</option>
                                    <option value="당일변경">당일변경</option>
                                    <option value="당일취소">취소</option>
                                    <option value="NS">NS</option>
                                    <option value="입실">입실</option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="btns3 mgB80">
                    <a class="btn_sms mr-10 pointer" @click="cancel()">취소</a>
                    <a class="btn_cancle pointer" @click="register()">등록</a>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    EmptyCheck
} from '@/utils/validation.js'
import {
    GetUrlParams,
    GetFormatToday
} from '@/utils/common.js'
export default {
    data: () => ({
        idx: -1,
        eapYn: 'N',
        staffName: '', // 상담사
        name: '', // 고객명
        consDate: '', // 상담날짜
        consTime: '', // 상담시간
        consTimeList: [],
        memo: '', // 메모
        reservationStatus: '',

        isAutoCounselor: false, // 상담사 자동완성 on/off
        autoSearchCounselor: '', // 상담사 자동완성 검색
        counselorList: [], // 상담사 리스트 (자동완성)

        centerName: '', // 센터명
        idxCrmCenter: -1, // 센터 idx
        idxCrmClient: -1, // 고객 idx
        idxCrmStaff: -1, // 상담사 idx

        isAutoClient: false, // 유저 자동완성 on/off
        autoSearchClient: '', // 유저 자동완성 검색
        clientList: [] // 유저 리스트 (자동완성)
        , workTimeList: []
    }),

    computed: {
        filterCounselorList() {
            const str = this.autoSearchCounselor
            const reg = /[^가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9|\s]/.test(str)
            if (reg === false && str !== '' && str !== ' ') {
                this.isAutoCounselor = true
                var filterNames = []
                filterNames = this.counselorList.filter((el) => {
                    return el.name.match(str);
                });
                if (filterNames.length > 0) {
                    return filterNames
                } else {
                    this.isAutoCounselor = false
                }
            } else {
                this.isAutoCounselor = false
            }
        },

        filterClientList() {
            const str = this.autoSearchClient
            const reg = /[^가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9|\s]/.test(str)
            if (reg === false && str !== '' && str !== ' ') {
                this.isAutoClient = true
                var filterNames = []
                filterNames = this.clientList.filter((el) => {
                    return el.name.match(str);
                });
                if (filterNames.length > 0) {
                    return filterNames
                } else {
                    this.isAutoClient = false
                }
            } else {
                this.isAutoClient = false
            }
        }
    },

    mounted() {
        this.consDate = GetFormatToday()
        this.getCounselorList()
        this.getUserList()
        this.setConsTime()
        var oParams = GetUrlParams()
        this.idx = oParams.idx
        
    },

    methods: {

        checkStaff() {
            if(this.idxCrmStaff === -1) {
                alert('상담사부터 입력해주세요')
                return
            }
        },

        getStaffSchedule() {
            let flag = false

            // 상담사 자동완성 체크 부분
            if (!EmptyCheck(this.staffName, '상담사를')) {
                return false
            } else {
                for (var staff of this.counselorList) {
                    if (staff.name === this.staffName && staff.idx === this.idxCrmStaff) {
                        flag = true
                    }
                }
            }

            if (!flag) {
                alert('상담사를 다시 확인해주세요')
                return false
            }

            if (this.idxCrmStaff === -1) {
                alert('존재하지 않는 상담사입니다.')
                return false
            }

            var params = {
                idxCrmStaff: this.idxCrmStaff,
                consDate: this.formatDate(this.consDate)
            }
            this.axios.get(`/api/v1/user/staff/schedule`, {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {

                        this.isValidCheck = true
                        this.schedule = res.data.schedule
                        this.setConsTime()
                        if (res.data.schedule.length > 0) {
                            for (var i = 0; i < res.data.schedule.length; i++) {
                                // 근무요일 테이블 매핑 (자기 자신거)
                                if (res.data.schedule[i].idx !== Number(this.idx)) {
                                    this.workTimeList = res.data.schedule[i].consTime.split('/')
                                    for (var consTime of this.consTimeList) {
                                        // 상담사 일하는 시간 외에는 선택하지 못하도록 추가 20210118
                                        if (res.data.workTime.work_time.indexOf(consTime.time) !== -1) {
                                            consTime.can = true
                                        } else {
                                            consTime.can = false
                                        }
                                        for (var work of this.workTimeList) {
                                            console.log('work ', work, ' time', consTime.time)
                                            if (work === consTime.time) {
                                                consTime.can = false
                                                consTime.use = false
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            //this.setWorkTimeList()
                            //this.setConsTime()
                            if (res.data.workTime !== null && res.data.workTime !== undefined && res.data.workTime != 'null') {
                                for (var consTime of this.consTimeList) {
                                    if (res.data.workTime.work_time.indexOf(consTime.time) !== -1) {
                                        consTime.can = true
                                    } else {
                                        consTime.can = false
                                    }
                                }
                            } else {
                                for (var consTime of this.consTimeList) {
                                    consTime.can = false
                                }
                            }
                        }
                    }
                }).catch(err => {
                    console.log(err)
                    if (err.response.data.path === '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })

        },

        // 센터 자동완성 입력 감지
        watchEvent(e, type) {
            if (type === 'counselor') {
                this.autoSearchCounselor = e.target.value
            } else {
                this.autoSearchClient = e.target.value
            }
        },

        // 자동완성 끄기
        removeValue() {
            if (document.querySelector('.r').classList.contains('key')) {
                document.querySelector('.r').classList.remove('key');
                document.querySelector('.r li.sel').classList.remove('sel');
            }
            if (document.querySelector('.c').classList.contains('key')) {
                document.querySelector('.c').classList.remove('key');
                document.querySelector('.c li.sel').classList.remove('sel');
            }
        },

        // 자동완성 선택한 경우 센터명 변경
        changeValue(str, type, idx, centerIdx, centerName) {
            if (type === 'counselor') {
                // console.log('idx', idx)
                // console.log('centerIdx', centerIdx)
                // console.log('centerName', centerName)
                this.isAutoCounselor = false
                this.staffName = str
                this.idxCrmStaff = idx
                this.getUserList()
                this.getStaffSchedule()
            } else {
                // console.log('idx', idx)
                this.isAutoClient = false
                this.name = str
                this.idxCrmClient = idx
            }
        },

        // 자동완성 켜기 및 선택
        selectValue(keycode, str, idx, centerIdx, centerName) {
            if (this.isAutoCounselor) {
                const hasClass = document.querySelector('.r').classList.contains('key');
                if (keycode === 'down') {
                    if (!hasClass) {
                        const thisEl = document.querySelectorAll('.r li')[0];
                        document.querySelector('.r').classList.add('key');
                        thisEl.classList.add('sel');
                        thisEl.focus();
                    } else {
                        const lastEl = document.querySelector('.r li:last-child');
                        const thisEl = document.querySelector('.r li.sel');
                        const nextEl = thisEl.nextElementSibling;
                        if (!lastEl.classList.contains('sel')) {
                            thisEl.classList.remove('sel');
                            nextEl.classList.add('sel');
                            nextEl.focus();
                        }
                    }
                }

                if (keycode === 'up' && hasClass) {
                    const firstEl = document.querySelectorAll('.r li')[0];
                    const thisEl = document.querySelector('.r li.sel');
                    const prevEl = thisEl.previousElementSibling;
                    if (!firstEl.classList.contains('sel')) {
                        thisEl.classList.remove('sel');
                        prevEl.classList.add('sel');
                        prevEl.focus();
                    } else {
                        document.querySelector('.s').focus();
                    }
                }

                if (keycode === 'enter' && hasClass) {
                    this.changeValue(str, 'counselor', idx, centerIdx, centerName);
                }
            }

            if (this.isAutoClient) {
                const hasClass = document.querySelector('.c').classList.contains('key');
                if (keycode === 'down') {
                    if (!hasClass) {
                        const thisEl = document.querySelectorAll('.c li')[0];
                        document.querySelector('.c').classList.add('key');
                        thisEl.classList.add('sel');
                        thisEl.focus();
                    } else {
                        const lastEl = document.querySelector('.c li:last-child');
                        const thisEl = document.querySelector('.c li.sel');
                        const nextEl = thisEl.nextElementSibling;
                        if (!lastEl.classList.contains('sel')) {
                            thisEl.classList.remove('sel');
                            nextEl.classList.add('sel');
                            nextEl.focus();
                        }
                    }
                }

                if (keycode === 'up' && hasClass) {
                    const firstEl = document.querySelectorAll('.c li')[0];
                    const thisEl = document.querySelector('.c li.sel');
                    const prevEl = thisEl.previousElementSibling;
                    if (!firstEl.classList.contains('sel')) {
                        thisEl.classList.remove('sel');
                        prevEl.classList.add('sel');
                        prevEl.focus();
                    } else {
                        document.querySelector('.s').focus();
                    }
                }

                if (keycode === 'enter' && hasClass) {
                    this.changeValue(str, 'client', idx);
                }
            }
        },

        // 상담사 전체목록 호출
        getCounselorList() {
            var params = {
                idxCrmCenter: -1
            }
            this.axios.get('/api/v2/user/list', {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        this.counselorList = res.data.userList
                    } else {
                        alert('등록된 상담사가 없습니다.')
                        history.back()
                    }
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // 고객 전체목록 호출
        getUserList() {
            this.axios.get('/api/v1/client/all')
                .then(res => {
                    if (res.data.err === 0) {
                        this.clientList = res.data.client
                        if(this.idx !== undefined) {
                            for(var client of res.data.client) {
                                if(client.idx === Number(this.idx)) {
                                    this.name = client.name
                                    this.idxCrmClient = client.idx
                                    this.eapYn = client.eapYn
                                    break;
                                }
                            }
                        }
                    } else {
                        alert(res.data.result)
                    }
                })
               .catch(err => {
                    console.log(err)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // 상담예약관리 페이지로 돌아가기
        cancel() {
            //this.$router.push('/convention/consult_reserve')
            history.back()
        },

        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
            return [year, month, day].join('-');
        },

        // 예약하기
        register() {

            let flag = false

            // 상담사 자동완성 체크 부분
            if (!EmptyCheck(this.staffName, '상담사를')) {
                return false
            } else {
                for (var staff of this.counselorList) {
                    if (staff.name === this.staffName && staff.idx === this.idxCrmStaff) {
                        flag = true
                    }
                }
            }

            if (!flag) {
                alert('상담사를 다시 확인해주세요')
                return false
            }

            if (this.idxCrmStaff === -1) {
                alert('존재하지 않는 상담사입니다.')
                return false
            }

            flag = false
            // 고객 자동완성 체크 부분
            if (!EmptyCheck(this.name, '고객을')) {
                return false
            } else {
                for (var client of this.clientList) {
                    if (client.name === this.name && client.idx === Number(this.idxCrmClient)) {
                        flag = true
                        break;
                    }
                }
            }

            if (!flag) {
                alert('고객명을 다시 확인해주세요')
                return false
            }

            if (this.idxCrmClient === -1) {
                alert('존재하지 않는 고객입니다.')
                return false
            }


            if (!EmptyCheck(this.formatDate(this.consDate), '상담날짜를')) {
                return false
            }

            // 근무요일 테이블 파라미터형태로 변경
            let consTimeList = []
            let copyConsTimeList = JSON.parse(JSON.stringify(this.consTimeList))
            for (var item of copyConsTimeList) {
                if (item.use) {
                    delete item['use']
                    consTimeList.push(item)
                }
            }
            
            // console.log('consTimeList', consTimeList)
            this.consTime = ''
            consTimeList.reverse();
            for(var time of consTimeList) {
                this.consTime = time.time + "/" + this.consTime
            }

            if (!EmptyCheck(this.consTime, '상담시간을')) {
                return false
            }

            if (!EmptyCheck(this.reservationStatus, '예약현황을')) {
                return false
            }

            var params = {
                consTime: this.consTime,
                consDate: this.formatDate(this.consDate),
                memo: this.memo,
                name: this.name,
                idxCrmStaff: this.idxCrmStaff,
                idxCrmClient: this.idxCrmClient,
                reservationStatus: this.reservationStatus
            }

            this.axios.post('/api/v1/cons', params)
                .then(res => {
                    if (res.data.err === 0) {
                        alert('등록 되었습니다.')
                        this.$router.push('/convention/consult_reserve')
                    } else {
                        alert(res.data.result)
                    }
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // 상담시간 array 구조 생성
        setConsTime() {
            this.consTimeList = []
            let time = 9
            for (var i = 0; i < 26; i++) {
                if (time < 10) {
                    if (i % 2 == 0) {
                        this.consTimeList.push({
                            time: '0' + (time) + ':00',
                            use: false
                            , can: true
                          })
                    } else {
                        this.consTimeList.push({
                            time: '0' + (time) + ':30',
                            use: false
                            , can: true
                         })
                        time += 1
                    }
                } else  {
                    if (i % 2 == 0) {
                        this.consTimeList.push({
                        time: (time) + ':00',
                        use: false
                     })
                    } else {
                        this.consTimeList.push({
                        time: (time) + ':30',
                        use: false
                        , can: true
                     })
                        time += 1
                    }
                }
            }
        }
    }
}
</script>

<style lang="scss">
#app {
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    .search {
        position: relative;
        margin: 0 auto;
        width: 100%;
        max-width: 600px;

        .s {
            float: left;
            margin-left: 15px;
            width: 255px;
            height: 30px;
            object-fit: contain;
            border-radius: 3px;
            border: solid 1px #cccccc;
            background-color: #ffffff;
            padding-left: 15px;

            &::placeholder {
                font-size: 14px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 4.29;
                letter-spacing: normal;
                text-align: left;
                color: #cccccc;
            }
        }

        .c {
            display: none;
            position: absolute;
            left: 0;
            top: 28px;
            width: 100%;
            height: 156px;
            overflow-y: auto;
            list-style: none;
            padding-left: 20px;

            &.show {
                display: block;
                z-index: 10;
            }

            li {
                margin-top: -1px;
                padding: 0 20px;
                width: 100%;
                height: 40px;
                background-color: #fff;
                box-sizing: border-box;
                border: 1px solid #dcdcdc;
                outline: none;
                font-size: 16px;
                line-height: 40px;
                cursor: pointer;

                &:hover,
                &.sel {
                    background-color: darken(#fff, 5%);
                }

                &:last-child {
                    border-radius: 0px 0px 4px 4px;
                }
            }
        }

        .r {
            display: none;
            position: absolute;
            left: 0;
            top: 28px;
            width: 100%;
            height: 156px;
            overflow-y: auto;
            list-style: none;
            padding-left: 20px;

            &.show {
                display: block;
                z-index: 10;
            }

            li {
                margin-top: -1px;
                padding: 0 20px;
                width: 100%;
                height: 40px;
                background-color: #fff;
                box-sizing: border-box;
                border: 1px solid #dcdcdc;
                outline: none;
                font-size: 16px;
                line-height: 40px;
                cursor: pointer;

                &:hover,
                &.sel {
                    background-color: darken(#fff, 5%);
                }

                &:last-child {
                    border-radius: 0px 0px 4px 4px;
                }
            }
        }

        p {
            padding: 10px 0;
            text-align: right;
            font-size: 12px;
        }
    }
}

.point {
    font-family: gulim;
    font-size: 9pt;
    color: #FAF7E7;
}

.textbox {
    font-family: gulim;
    font-size: 9pt;
    color: #736257;
    height: 20px;
}

.msg_wrap {
    width: 230px;
}

.msg_deco_wrap {
    border-radius: 13px 13px 0 0;
    background: #efefef;
    padding: 12px 0 0 0;
}

.msg_deco {
    width: 40px;
    height: 2px;
    background: #ababab;
    border-radius: 20px;
    margin: 0 auto;
}

.msg_box {
    background: #efefef;
    padding: 8px 20px 10px 20px;
    display: block;
    height: 200px;
}

.msg_box textarea {
    width: 100%;
    height: 100%
}

.msg_send {
    background: #efefef;
    padding: 0 20px 20px 20px;
}

.msg_footer {
    background: #efefef;
    border-radius: 0 0 13px 13px;
    padding: 0 20px 20px 20px;
    text-align: center;
}
</style>
